import React from "react";
import ToDo from "./ToDo";
import "./app.css";
export default function App() {
  return (
    <div className="app">
      <ToDo />
    </div>
  );
}
